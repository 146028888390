import React from "react"
import { Link } from "gatsby"
//import axios from "axios"
//import JSONData from "../../content/articles.json"
import { useRequest } from "../../utils/request" 

const MediumArticles = (props) => {
  const url = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F@mlombog"

  const { posts, loading, error } = useRequest(url)
  if (loading) return <p>Loading...</p>
  if (error) return <p>There was an error!</p>

  const filterByCategories = (list, filters) => {
    return list.filter(post =>
      filters.every(filter => post.categories.includes(filter))
    )
  }

  console.log(props)
  let res = []
  if (props.tag != null) res = filterByCategories(posts, [props.tag])
  else res = posts
  
  return (
    <div className="row">
      {res.slice(0, props.limit).map((post, i) => {
        const title = post.title
        const author = post.author
        const date = new Date(post.pubDate).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      
        var tmp = document.createElement('div');
        tmp.innerHTML = post.description;
        const thumbnail = tmp.querySelector('img').getAttribute('src');
        
        return (
          <div key={i} className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="post-image">
                <a href={post.link} target="_blank" rel="noreferrer">
                  <img src={thumbnail} alt="blog" />
                </a>
              </div>
              <div className="post-content">
                
                <ul className="post-meta d-flex justify-content-between align-items-center">
                <li>
                      <div className="post-author d-flex align-items-center">
                        <span>{author}</span>
                      </div>
                    </li>
                  <li>
                    <i className="flaticon-calendar"></i> {date}
                  </li>
                </ul>
                <h3>
                  <a href={post.link} target="_blank" rel="noreferrer">
                    {title}
                  </a>
                </h3>
                <div className="widget-area">
                  <div className="widget widget_tag_cloud">
                    <h3 className="widget-title"></h3>

                    <div className="tagcloud">
                      {post.categories.map((item, i) => {
                        return <Link key={i} to={`/articles?tag=${item}`}>{item}</Link>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MediumArticles

