import React from "react"
import { Link } from "gatsby"
//import axios from "axios"
//import JSONData from "../../content/articles.json"
import { useRequest } from "../../utils/request";

const BlogSidebarArticles = () => {
  const url = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F@mlombog"

  const { posts, loading, error } = useRequest(url);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>There was an error!</p>;

  var allCategories = []
  posts.map(item => {
    item.categories
      .toString()
      .split(",")
      .map(cat => {
        allCategories.push(cat)
      })
  })
  var uniqueCategories = [...new Set(allCategories)].sort()

  return (
    <div className="widget-area">
      <div className="widget widget_tag_cloud">
        <h3 className="widget-title">Categories</h3>

        <div className="tagcloud">
          <Link key={99} to={`/articles`}>{"All Articles"}</Link>
          {uniqueCategories.map((item, i) => {
            return <Link key={i} to={`/articles?tag=${item}`}>{item}</Link>
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogSidebarArticles
