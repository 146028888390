import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import MediumArticles from "./MediumArticles"
import BlogSidebarArticles from "./BlogSidebarArticles"

const MediumArticlesAll = (props) => {
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            Articles
          </span>
          <h2>Let's Talk About Tech</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <MediumArticles limit={100} tag={props.tag} />
          </div>
          <div className="col-lg-4 col-md-12">
            <BlogSidebarArticles />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MediumArticlesAll
