import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import MediumArticlesAll from '../components/BlogContent/MediumArticlesAll'

const Articles = ({location}) => {

    
    const params = new URLSearchParams(location.search);
    const tag = params.get("tag");

    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Articles" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Articles" 
            />
            <MediumArticlesAll tag={tag} />
            <Footer />
        </Layout>
    );
}

export default Articles;